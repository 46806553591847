<template>
  <div class="downloadcenter">
    <div class="bg">
      下载中心
    </div>
    <div class="middle">转换插件及编辑器</div>
    <div class="container">
      <ul>
        <li v-for="item in downloadlist" :key="item.id">
          <img :src="item.src" alt="">
          <p>{{item.tit}}</p>
          <div class="btns">
            <!-- <img src="../../assets/image/download/下载.png" alt=""> -->
            <img src="../../assets/image/download/视频.png" alt="" @click="play(item.tit)">
          </div>
        </li>
      </ul>
    </div>
    <div id="mask" ref="mask">
      <i class="el-icon-close" @click="closeplay"></i>
      <div class="pink"><video src="../../assets/video/黑洞介绍.mp4" muted autoplay loop controls id="dplayer" ref="video"></video></div>
    </div>
    <More/>
  </div>
</template>

<script>
import More from '@/components/product/more'
import pic1 from '../../assets/image/download/icon (1).png'
import pic2 from '../../assets/image/download/icon (2).png'
import pic3 from '../../assets/image/download/icon (3).png'
import pic4 from '../../assets/image/download/icon (4).png'
import pic5 from '../../assets/image/download/icon (5).png'
import pic6 from '../../assets/image/download/icon (6).png'
import pic7 from '../../assets/image/download/icon (7).png'
import video1 from '../../assets/video/插件视频/3d插件.mp4'
import video2 from '../../assets/video/插件视频/bentley插件.mp4'
import video3 from '../../assets/video/插件视频/revit插件(1).mp4'
export default {
  components: {
    More
  },
  data () {
    return {
      downloadlist: [
        {id: 1, tit: 'Revit模型数据插件', src: pic7},
        {id: 2, tit: '3Dsmax模型数据插件', src: pic2},
        {id: 3, tit: 'Navisworks模型数据导出插件', src: pic5},
        {id: 4, tit: 'FBX模型数据导出插件', src: pic4},
        {id: 5, tit: 'IFC 模型数据导出插件', src: pic6},
        {id: 6, tit: 'Bentley 模型数据导出插件', src: pic3},
        {id: 7, tit: '虫洞编辑器', src: pic1},
      ]
    }
  },
  methods: {
    play(name) {
      let video
      switch (name) {
        case 'Revit模型数据插件':
          video = video3;
          break;
        case '3Dsmax模型数据插件':
          video = video1;
          break;
        case 'Navisworks模型数据导出插件':
          video = "";
          break;
        case 'FBX模型数据导出插件':
          video = "";
          break;
        case '虫洞编辑器':
          video = "";
          break;
        case 'IFC 模型数据导出插件':
          video = "";
          break;
        case  'Bentley 模型数据导出插件':
          video = video2;
      }
      if (!video) {
        this.$message.error('该视频敬请期待')
        return false
      }
      this.$refs.mask.style.display = 'block'
      this.$refs.mask.style.height = document.getElementById('app').clientHeight + 'px'
      this.$refs.video.src = video
    },
    closeplay () {
      this.$refs.mask.style.display = 'none'
    }
  }
}
</script>
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
#mask{
  width: 100%;
  // opacity: 0.6;
  background-color: black;
  // background: rgba(0,0,0,1);
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 1001;
  display: none;
  i{
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    font-size: 30px;
    z-index: 999;
    cursor: pointer;
  }
  .pink{
    width: 80%;
    height: 80%;
    // margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    video{
      width: 100%;
      height:100%;
      object-fit:fill;
    }
  }
}
.downloadcenter{
  .bg{
    background: url('../../assets/image/background/bg (8).jpg') center center;
    height: 60vh;
    color: #fff;
    text-align: center;
    line-height: 60vh;
    font-size: 60px;
  }
  .middle{
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #000;
    text-align: center;
    font-size: 20px;
  }
  .container{
    ul{
      overflow: hidden;
      padding: 50px 0;
      // background-color: pink;
      // border: 1px solid #000;
      li{
        width: calc(50% - 20px);
        float: left;
        overflow: hidden;
        padding: 20px;
        height: 120px;
        line-height: 80px;
        margin-right: 20px;
        margin-bottom: 20px;
        // border: 1px solid #000;
        box-shadow: 0 6px 9px rgba(0, 0, 0, 0.2);
        border-bottom: none;
        @media screen and (max-width:768px) {
          width: 80%;
          float: none;
          margin: 30px auto;
        }
        img{
          width: 60px;
          float: left;
          margin-top: 10px;
        }
        p{
          margin-left: 10px;
          float: left;
        }
        .btns{
          float: right;
          width: 80px;
          margin-top: 20px;
          img{
            width: 30%;
            float: left;
            cursor: pointer;
          }
        }
      }
      li:nth-child(odd) {
        // border-right: 1px solid #000;
        border-right: none;
      }
      li:last-child{
        // border-right: 1px solid #000;
        // border-bottom: 1px solid #000;
        margin-bottom: 0;
      }
      li:nth-last-child(2){
        // border-bottom: 1px solid #000;
      }
    }
  }
}
</style>
