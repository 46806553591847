<template>
  <div class="more">
    <p>想要了解更多信息？</p>
    <p style="margin-bottom: 30px">欢迎致电咨询或联系秉匠销售顾问。</p>
    <div class="wrap">
      <a href="javascript:void(0)" @click="to('contactus')">
        <span>联系我们</span>
        <span class="top"></span>
        <span class="right"></span>
        <span class="bottom"></span>
        <span class="left"></span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    to(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.more {
  height: 60vh;
  background: url("../../assets/image/product/bg_engine02.jpg");
  text-align: center;
  font-size: 30px;
  color: #fff;
  p {
    line-height: 50px;
  }
  p:first-child {
    padding-top: 150px;
  }
  .wrap a {
    width: 120px;
    color: #0aaff1;
    padding: 5px 10px;
    border: 2px solid  rgba(10, 175, 241, .5);
    position: relative;
  }
  .wrap a .top {
    display: inline-block;
    position: absolute;
    left: -1px;
    top: -2px;
    width: 0px;
    height: 2px;
    background: #0aaff1;
    transition: 0.5s;
  }
  .wrap a .right {
    display: inline-block;
    position: absolute;
    right: -1px;
    bottom: 0;
    width: 2px;
    height: 0px;
    background: #0aaff1;
    transition: 0.5s;
  }
  .wrap a .bottom {
    display: inline-block;
    position: absolute;
    bottom: -1px;
    right: -1px;
    width: 0px;
    height: 2px;
    background: #0aaff1;
    transition: 0.5s;
  }
  .wrap a .left {
    display: inline-block;
    position: absolute;
    left: -1px;
    top: 0;
    width: 2px;
    height: 0px;
    background: #0aaff1;
    transition: 0.5s;
  }
  .wrap a:hover .top,
  .wrap a:hover .bottom {
    width: 101%;
    transition: 0.5s;
  }
  .wrap a:hover .right,
  .wrap a:hover .left {
    height: 100%;
    transition: 0.5s;
  }
}
</style>
